const Config = {
  SITE_TITLE: 'Holiday Planner',
  ACCESS_TITLE: "Planner",
  API_BASE: '/api/v1',
  UPDATE_INTERVAL: 600*1000,
  NAME: process.env.REACT_APP_NAME,
  VERSION: process.env.REACT_APP_VERSION,
  DESCRIPTION: process.env.REACT_APP_DESCRIPTION,
}

export default Config;
